import { NgModule } from '@angular/core';
import { DatePickerDirective } from './directives/datepicker/datepicker.directive';

@NgModule({
  imports: [],
  declarations: [
    DatePickerDirective
  ],
  providers: [],
  exports: [
    DatePickerDirective
  ]
})

export class SharedModule {}
