import { Survey } from './survey';

export class SurveyType {
  private _surveys: Array<Survey> = [];
  private _title: string;
  private _order: number;
  private prefData = {
    aboutMeFilterItems: {
      title: 'About Me',
      order: 0,
    },
    scheduledFilterItems: {
      title: 'Scheduled Surveys',
      order: 1,
    },
    studyFilterItems: {
      title: 'Journal Entries',
      order: 2,
    },
    dairyFilterItems: {
      title: 'Daily Entries',
      order: 3,
    },
  };

  constructor(private _type: string) {
    this._title = this.prefData[_type].title;
    this._order = this.prefData[_type].order;
  }

  addSurvey(survey: Survey) {
    this._surveys.push(survey);
  }

  isChildsVisible() {
    return this._type !== 'aboutMeFilterItems' && this._type !== 'scheduledFilterItems';
  }

  get title() {
    return this._title;
  }

  get order() {
    return this._order;
  }

  get surveys() {
    return this._surveys;
  }

  get type() {
    return this._type;
  }

  get passages() {
    let passages = 0;

    for (const survey of this._surveys) {
      passages += survey.numberOfPassages;
    }

    return passages;
  }

  get isPartiallySelected() {
    let selected = 0;

    for (const survey of this._surveys) {
      if (survey.isSelected) {
        selected++;
      }
    }

    return selected > 0 && this._surveys.length !== selected;
  }

  get isSelected() {
    let isSelected = false;

    for (const survey of this._surveys) {
      if (survey.isSelected) {
        isSelected = true;
        break;
      }
    }

    return isSelected;
  }

  set isSelected(value) {
    for (const survey of this._surveys) {
      survey.isSelected = value;
    }
  }
}
