const DATE_SUBMIT_FORMAT = 'YYYY-MM-DD';
const DATE_FILTER_FORMAT = 'MM/dd/yyyy';
const MOMENT_DATE_FILTER_FORMAT = 'MM/DD/YYYY';
const DATE_PICKER_FORMAT = 'mm/dd/yy';

export {
    DATE_SUBMIT_FORMAT,
    DATE_FILTER_FORMAT,
    DATE_PICKER_FORMAT,
    MOMENT_DATE_FILTER_FORMAT,
};
