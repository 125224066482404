<div class="timeline-results">
  <div class="loader loader--orientation-horizontal" *ngIf="isLoading">
    <svg class="loader__image loader__image--svg">
      <circle cx="16" cy="16" r="12" stroke-linecap="round"/>
    </svg>
    <div class="loader__header">Loading...</div>
  </div>
  <ng-template ngFor let-question [ngForOf]="questions | keyvalue:sortPostionOrder">
    <div 
      class="timeline-results-question" 
      [ngClass]="'timeline-results-question__' + question.value.type | lowercase"
      *ngIf="!question.value.isHidden && !question.value.isSkipped">
      <div class="timeline-results-title" [innerHTML]="question.value.title"></div>
    
      <div class="timeline-results-answers">
        <ng-template ngFor let-answer [ngForOf]="question.value.answers | keyvalue:sortPostionOrder">
          <div class="timeline-results-answer" [ngSwitch]="question.value.type">
            <span *ngSwitchCase="'FreeText'">
              {{ getTrimmedAnswer(answer.value.value, 255) }}
            </span>
            <span *ngSwitchCase="'Address'" [innerHTML]="answer.value.value"></span>
            <span *ngSwitchCase="'PainSimple'">
              -
            </span>
            <span *ngSwitchDefault>
              {{ answer.value.value }}
            </span>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-template>
</div>
