import { Question } from './question';

export class Answer {
  questions: Map<number, Question> = new Map();
  attributeSortPosition: number = null;
  answerSortPosition: number = null;

  constructor(public id: number, public title: string, public value: string) {}

  addQuestion(question: Question) {
    this.questions.set(question.id, question);
  }

  get sortPosition() {
    return this.attributeSortPosition !== null ? this.attributeSortPosition : this.answerSortPosition;
  }
}
