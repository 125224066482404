<div
    class="paper-content"
    infinite-scroll
    [infiniteScrollDistance]="scrollDistance"
    [infiniteScrollContainer]="scrollContainer"
    [fromRoot]="true"
    (scrolled)="onScrollDown()"
>
  <div class="timeline-survey-type theme-tonic">
    <app-timeline-survey-type>
    </app-timeline-survey-type>
  </div>
  <div class="timeline-empty" *ngIf="isEmptyTimelineGroups">
    <h3>Unfortunately, nothing to display</h3>
    <div class="timeline-empty-image"></div>
  </div>
  
  <div *ngIf="!isEmptyTimelineGroups">
    <app-timeline-group 
      *ngFor="let surveysGroup of groups; trackBy: trackByFn; first as isFirst; last as isLast"
      [group]="surveysGroup"
      [ngClass]="{
        'first': (isFirst && !isEmptyTimelineGroups),
        'last': (isLast && groups.length > 1),
        'single': isSingleGroup && !isSingleSurvey,
        'bordered': (!isEmptyTimelineGroups && !isSingleSurvey)}"
    ></app-timeline-group>
  </div>
  <div class="timeline-scroll-loader loader loader--orientation-horizontal" *ngIf="isLoading">
    <svg class="loader__image loader__image--svg">
      <circle cx="16" cy="16" r="12" stroke-linecap="round"/>
    </svg>
    <div class="loader__header">Loading...</div>
  </div>
</div>