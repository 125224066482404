<form [formGroup]="datesGroup" class="date-range-form">
  <div class="date-range-form-left">
    <label>Start Date</label>
    <div class="input-wrapper">
      <input 
        type="text" 
        maxlength="10" 
        class="input input--mod-with-icon form-control" 
        formControlName="startDate"
        readonly
        appDatepicker
        #startDatePicker="datePickerDirective"
        [minDate]="minDate"
        [maxDate]="endDate"
        (dateChange)="onStartDateChange($event)">

      <svg class="icon" viewBox="0 0 24 24" (click)="startDatePicker.showDatePicker()">
        <path d="M21,22 C21.552,22 22,21.551 22,21 L22,10 L2,10 L2,21 C2,21.551 2.448,22 3,22 L21,22 Z M3,4 C2.448,4 2,4.449 2,5 L2,8 L22,8 L22,5 C22,4.449 21.552,4 21,4 L18,4 L18,5 C18,5.552 17.553,6 17,6 C16.447,6 16,5.552 16,5 L16,4 L8,4 L8,5 C8,5.552 7.553,6 7,6 C6.447,6 6,5.552 6,5 L6,4 L3,4 Z M21,2 C22.654,2 24,3.346 24,5 L24,21 C24,22.654 22.654,24 21,24 L3,24 C1.346,24 0,22.654 0,21 L0,5 C0,3.346 1.346,2 3,2 L6,2 L6,1 C6,0.448 6.447,0 7,0 C7.553,0 8,0.448 8,1 L8,2 L16,2 L16,1 C16,0.448 16.447,0 17,0 C17.553,0 18,0.448 18,1 L18,2 L21,2 Z M6.5,12 C7.328,12 8,12.671 8,13.5 C8,14.329 7.328,15 6.5,15 C5.672,15 5,14.329 5,13.5 C5,12.671 5.672,12 6.5,12 Z M12,12 C12.828,12 13.5,12.671 13.5,13.5 C13.5,14.329 12.828,15 12,15 C11.172,15 10.5,14.329 10.5,13.5 C10.5,12.671 11.172,12 12,12 Z M17.5,12 C18.328,12 19,12.671 19,13.5 C19,14.329 18.328,15 17.5,15 C16.672,15 16,14.329 16,13.5 C16,12.671 16.672,12 17.5,12 Z M6.5,17 C7.328,17 8,17.671 8,18.5 C8,19.329 7.328,20 6.5,20 C5.672,20 5,19.329 5,18.5 C5,17.671 5.672,17 6.5,17 Z M12,17 C12.828,17 13.5,17.671 13.5,18.5 C13.5,19.329 12.828,20 12,20 C11.172,20 10.5,19.329 10.5,18.5 C10.5,17.671 11.172,17 12,17 Z M17.5,17 C18.328,17 19,17.671 19,18.5 C19,19.329 18.328,20 17.5,20 C16.672,20 16,19.329 16,18.5 C16,17.671 16.672,17 17.5,17 Z"></path>
      </svg>
    </div>
  </div>

  <div class="date-range-form-right">
    <label>End Date</label>
    <div class="input-wrapper">
      <input 
        type="text" 
        maxlength="10" 
        class="input input--mod-with-icon form-control" 
        formControlName="endDate"
        readonly
        appDatepicker
        #endDatePicker="datePickerDirective"
        [minDate]="startDate"
        [maxDate]="maxDate"
        (dateChange)="onEndDateChange($event)">

      <svg class="icon" viewBox="0 0 24 24" (click)="endDatePicker.showDatePicker()">
        <path d="M21,22 C21.552,22 22,21.551 22,21 L22,10 L2,10 L2,21 C2,21.551 2.448,22 3,22 L21,22 Z M3,4 C2.448,4 2,4.449 2,5 L2,8 L22,8 L22,5 C22,4.449 21.552,4 21,4 L18,4 L18,5 C18,5.552 17.553,6 17,6 C16.447,6 16,5.552 16,5 L16,4 L8,4 L8,5 C8,5.552 7.553,6 7,6 C6.447,6 6,5.552 6,5 L6,4 L3,4 Z M21,2 C22.654,2 24,3.346 24,5 L24,21 C24,22.654 22.654,24 21,24 L3,24 C1.346,24 0,22.654 0,21 L0,5 C0,3.346 1.346,2 3,2 L6,2 L6,1 C6,0.448 6.447,0 7,0 C7.553,0 8,0.448 8,1 L8,2 L16,2 L16,1 C16,0.448 16.447,0 17,0 C17.553,0 18,0.448 18,1 L18,2 L21,2 Z M6.5,12 C7.328,12 8,12.671 8,13.5 C8,14.329 7.328,15 6.5,15 C5.672,15 5,14.329 5,13.5 C5,12.671 5.672,12 6.5,12 Z M12,12 C12.828,12 13.5,12.671 13.5,13.5 C13.5,14.329 12.828,15 12,15 C11.172,15 10.5,14.329 10.5,13.5 C10.5,12.671 11.172,12 12,12 Z M17.5,12 C18.328,12 19,12.671 19,13.5 C19,14.329 18.328,15 17.5,15 C16.672,15 16,14.329 16,13.5 C16,12.671 16.672,12 17.5,12 Z M6.5,17 C7.328,17 8,17.671 8,18.5 C8,19.329 7.328,20 6.5,20 C5.672,20 5,19.329 5,18.5 C5,17.671 5.672,17 6.5,17 Z M12,17 C12.828,17 13.5,17.671 13.5,18.5 C13.5,19.329 12.828,20 12,20 C11.172,20 10.5,19.329 10.5,18.5 C10.5,17.671 11.172,17 12,17 Z M17.5,17 C18.328,17 19,17.671 19,18.5 C19,19.329 18.328,20 17.5,20 C16.672,20 16,19.329 16,18.5 C16,17.671 16.672,17 17.5,17 Z"></path>
      </svg>
    </div>
  </div>
</form>