import { Component, OnInit, Inject, Input } from '@angular/core';
import { TimelineResultsService } from './timeline-results.service';
import { Question } from './question';

@Component({
  selector: 'app-timeline-results',
  templateUrl: './timeline-results.component.ng2.html',
  styleUrls: ['./timeline-results.component.scss']
})

export class TimelineResultsComponent implements OnInit {
  @Input() resultsLink: string;
  questions: Map<number, Question> = new Map();
  isLoading = true;

  constructor(
    private timelineResultsService: TimelineResultsService,
    @Inject('ApiContent') private apiContentService: any
  ) {}

  ngOnInit() {
    this.apiContentService
      .getDataByLink(this.resultsLink)
      .then((data) => {
        this.isLoading = false;
        this.questions = this.timelineResultsService.buildResultsTree(data);
      });
  }

  sortPostionOrder(a, b) {
    return a.value.sortPosition > b.value.sortPosition ? 1 : (b.value.sortPosition > a.value.sortPosition ? -1 : 0);
  }

  getTrimmedAnswer(value: string, limit: number) {
    if (value.length <= limit) {
      return value;
    }

    return value.slice(0, limit) + '...';
  }
}
