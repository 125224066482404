import {
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges
} from '@angular/core';
import { SurveyType } from '../survey-type/survey-type';
import { SurveyTypeFilterService } from '../survey-type/survey-type.service';

@Component({
  selector: 'app-timeline-survey-type-selector',
  templateUrl: './survey-type-selector.component.ng2.html',
  styleUrls: ['./survey-type-selector.component.scss'],
})

export class TimelineSurveyTypeSelectorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() surveyTypes: Array<SurveyType>;
  private selectedSurveyIdsSubscribtion;
  public isAllSelected: boolean;
  public isPartiallyChecked = false;
  public isSubItemsShown = false;

  constructor(private surveyTypeFilterService: SurveyTypeFilterService) {}

  ngOnInit() {
    this.isAllSelected = !this.surveyTypeFilterService.hasSelectedFilters();
    this.selectedSurveyIdsSubscribtion = this.surveyTypeFilterService.getSelectedSurveyIdsSubject()
      .subscribe(value => {
        this.onSurveyIdsChange(value);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.surveyTypes) {
      this.surveyTypes = changes.surveyTypes.currentValue;
    }
  }

  onAllSelectedChage(newValue: boolean) {
    if (newValue) {
      this.surveyTypeFilterService.clearAll();
      this.surveyTypeFilterService.rebuildSelectedSurveyIds();
    } else {
      this.surveyTypeFilterService.rebuildForUnselectedIds();
    }
  }

  onFilterChange(newValue: boolean) {
    this.surveyTypeFilterService.rebuildSelectedSurveyIds();
  }

  onSurveyIdsChange(ids) {
    this.isAllSelected = ids && !ids.length;
  }

  ngOnDestroy() {
    this.selectedSurveyIdsSubscribtion.unsubscribe();
  }
}
