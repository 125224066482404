<div class="survey-type-filter-triangle">
  <div class="survey-type-filter-triangle-wrapper">
    <div class="survey-type-filter-triangle-outer">
      <div class="survey-type-filter-triangle-inner"></div>
    </div>
  </div>
</div>
<div class="survey-type-filter">
  <ng-scrollbar>
    <div class="survey-type-filter-wrapper">
      <div class="survey-type-filter-item bordered parent">
        <label class="checkbox">
          <input type="checkbox"
                 id="allSurveys"
                 [(ngModel)]="isAllSelected"
                 (ngModelChange)="onAllSelectedChage($event)"
          />
          <span class="checkbox__block">
            <svg class="icon icon-checked">
              <use xlink:href="#icon-check"></use>
            </svg>
          </span>
        </label>
        <label for="allSurveys">All surveys</label>
      </div>

      <ng-template ngFor let-surveyType [ngForOf]="surveyTypes" let-i="index" let-first="first" let-last="last">
        <div class="survey-type-filter-item"
             [ngClass]="{'parent': (surveyType.surveys.length && isSubItemsShown), 'first': first, 'last': last}">
          <label class="checkbox"
                 [ngClass]="{'partial-checked': surveyType.isSelected && surveyType.isPartiallySelected, 'disabled': !surveyType.passages}">
            <input
              type="checkbox"
              [(ngModel)]="surveyType.isSelected"
              (ngModelChange)="onFilterChange($event)"
              [disabled]="!surveyType.passages"
              id="survey-type-{{i}}" />

            <span class="checkbox__block">
              <svg class="icon icon-partial-checked" *ngIf="surveyType.isSelected && surveyType.isPartiallySelected">
                <use xlink:href="#icon-minus"></use>
              </svg>
              <svg class="icon icon-checked" *ngIf="surveyType.isSelected && !surveyType.isPartiallySelected">
                <use xlink:href="#icon-check"></use>
              </svg>
            </span>
          </label>
          <label for="survey-type-{{i}}"
                 [ngClass]="{'disabled': !surveyType.passages}">
                {{ surveyType.title }} ({{ surveyType.passages }})
          </label>
          <label *ngIf="surveyType.isChildsVisible() && surveyType.surveys.length"
                 (click)="isSubItemsShown = !isSubItemsShown"
                 class="filter-arrow">
            <span [hidden]="isSubItemsShown">
              <svg class="icon icon-arrow-right">
                <use xlink:href="#icon-arrow-right"></use>
              </svg>
            </span>
            <span [hidden]="!isSubItemsShown">
              <svg class="icon icon-arrow-down">
                <use xlink:href="#icon-arrow-down"></use>
              </svg>
            </span>
          </label>
        </div>

        <ng-template [ngIf]="surveyType.isChildsVisible() && isSubItemsShown">
          <div
            class="survey-type-filter-item sub-item"
            *ngFor="let survey of surveyType.surveys; index as ii; last as isLast"
            [ngClass]="{'parent': surveyType.surveys.length > 1 && !isLast}">
            <label class="checkbox">
              <input
                type="checkbox"
                [(ngModel)]="survey.isSelected"
                (ngModelChange)="onFilterChange($event)"
                id="survey-{{i}}-{{ii}}" />
              <span class="checkbox__block">
                <svg class="icon icon-checked">
                  <use xlink:href="#icon-check"></use>
                </svg>
              </span>
            </label>
            <label for="survey-{{i}}-{{ii}}">{{ survey.title }} ({{ survey.numberOfPassages }})</label>
          </div>
        </ng-template>
      </ng-template>
    </div>
  </ng-scrollbar>
</div>
