import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app.module';
import { environment } from '../environments/environment';
import { LoggerAdapterDatadog } from '@tonic/logging/adapters/datadog';


// pre initialize LoggerAdapter for earlier error logging
LoggerAdapterDatadog.initializeStatic();

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
