import { Component, OnChanges, Input, SimpleChanges } from '@angular/core';
import { SurveyTypeFilterService } from '../survey-type/survey-type.service';
import { Survey } from '../survey-type/survey';

@Component({
  selector: 'app-timeline-survey-type-list',
  templateUrl: './survey-type-list.component.ng2.html',
  styleUrls: ['./survey-type-list.component.scss'],
})

export class TimelineSurveyTypeListComponent implements OnChanges {
  @Input() surveyTypes: Array<Survey>;

  constructor(private surveyTypeFilterService: SurveyTypeFilterService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.surveyTypes) {
      this.surveyTypes = changes.surveyTypes.currentValue;
    }
  }

  removeFilter(survey: Survey) {
    survey.isSelected = false;
    this.surveyTypeFilterService.rebuildSelectedSurveyIds();
  }
}
