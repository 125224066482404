import { Injectable } from '@angular/core';
import { Survey } from './survey';
import { SurveyType } from './survey-type';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SurveyTypeFilterService {
  private _surveyTypes: Array<SurveyType> = [];
  private _surveyIdsSubject = new Subject();

  constructor() {}

  loadDataToFilter(data) {
    for (const key of Object.keys(data)) {
      const surveyType = new SurveyType(key);
      const surveysData = data[key];

      for (const surveyObj of surveysData) {
        // Skip surveys without passages
        if (!surveyObj.numberOfPassage) {
          continue;
        }

        const survey = new Survey(surveyObj.surveyId, surveyObj.surveyTitle, surveyObj.numberOfPassage);

        surveyType.addSurvey(survey);
      }

      this._surveyTypes.push(surveyType);
    }

    this._surveyTypes.sort((i1, i2) => i1.order - i2.order);
  }

  hasSelectedFilters() {
    let hasSelected = false;

    surveyTypeLoop:
    for (const surveyType of this._surveyTypes) {
      for (const survey of surveyType.surveys) {
        if (survey.isSelected) {
          hasSelected = true;

          break surveyTypeLoop;
        }
      }
    }

    return hasSelected;
  }

  clearAll() {
    for (const surveyType of this._surveyTypes) {
      for (const survey of surveyType.surveys) {
        survey.isSelected = false;
      }
    }

    this.rebuildSelectedSurveyIds();
  }

  rebuildSelectedSurveyIds() {
    this._surveyIdsSubject.next(this.selectedSurveyIds);
  }

  rebuildForUnselectedIds() {
    this._surveyIdsSubject.next(null);
  }

  get selectedSurveyIds() {
    const surveyIds = [];

    for (const surveyType of this._surveyTypes) {
      for (const survey of surveyType.surveys) {
        if (survey.isSelected) {
          surveyIds.push(survey.id);
        }
      }
    }

    return surveyIds;
  }

  getSelectedSurveyIdsSubject() {
    return this._surveyIdsSubject;
  }

  get surveyTypes() {
    return this._surveyTypes;
  }

  get surveys() {
    const surveys: Array<Survey> = [];

    for (const surveyType of this._surveyTypes) {
      for (const survey of surveyType.surveys) {
        surveys.push(survey);
      }
    }

    return surveys;
  }
}
