<div class="form-control-show-password"
     [ngClass]="{'focused': isFocused}">
  <label class="checkbox">
    <input type="checkbox"
           id="showPassword-{{ fieldId }}"
           name="showPassword-{{ fieldId }}"
           [checked]="isCheckboxChecked"
           (change)="onShowPasswordChange()"
    />
    <span class="checkbox__block">
      <svg class="icon icon-checked">
        <use xlink:href="#icon-check"></use>
      </svg>
    </span>
  </label>
  <label for="showPassword-{{ fieldId }}">Show</label>
</div>