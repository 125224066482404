import { Answer } from './answer';

export class Question {
  answers: Map<number, Answer> = new Map();
  isHidden: boolean;
  isSkipped: boolean;
  sortPosition: number;

  constructor(public id: number, public type: string, public title: string) {}

  addAnswer(answer: Answer) {
    this.answers.set(answer.id, answer);
  }
}
