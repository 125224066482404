import { Component, ViewEncapsulation, OnInit, Input } from '@angular/core';
import * as _ from 'underscore';

interface GroupItem {
  date: string;
  surveys: Array<any>;
}

@Component({
  selector: 'app-timeline-group',
  templateUrl: './timeline-group.component.ng2.html',
  styleUrls: ['./timeline-group.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class TimelineGroupComponent implements OnInit {
  @Input() public group: GroupItem;

  public expired: Array<any> = [];
  public completed: Array<any> = [];
  public date = '';
  public surveys: any;

  ngOnInit() {
    this.date = this.group.date;
    this.expired = this.getStatusItems('expired');
    this.completed = this.getStatusItems('completed');
    this.surveys = this.completed.concat(this.expired);
  }

  trackByFn(index, item) {
    return item.survey.id;
  }

  public getStatusItems(status) {
    const statusItems = _.filter(this.group.surveys, function (item) {
      return item.status === status;
    });

    return statusItems;
  }
}
