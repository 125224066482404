<div class="timeline-group-border"></div>
<div class="timeline-group">
  <div class="timeline-group-header">
    <time datetime="">
      {{ date | date: 'MMMM d, y' }}
    </time>
    <div class="timeline-group-completed">
      <span *ngIf="completed.length">
        Completed ({{ completed.length }})
      </span>
      <span *ngIf="expired.length" class="timeline-group-expired">
        Expired ({{ expired.length }})
      </span>
    </div>
  </div>
  <app-timeline-item 
    *ngFor="let survey of surveys; trackBy: trackByFn; first as isFirst; last as isLast"
    [surveyData]="survey"
    [ngClass]="{
      'first': isFirst,
      'last': isLast
    }"
  ></app-timeline-item>
</div>
