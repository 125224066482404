import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
  OnInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import * as _ from 'underscore';

@Component({
  selector: 'app-timeline-list',
  templateUrl: './timeline-list.component.ng2.html',
  styleUrls: ['./timeline-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class TimelineListComponent implements OnInit, OnChanges {
  @Input() public groups: Array<any> = [];
  @Input() isLoading: boolean;
  @Output() scrolled = new EventEmitter();

  public isEmptyTimelineGroups = false;
  public scrollDistance = 1;
  public isSingleSurvey = false;
  public isSingleGroup = false;
  public scrollContainer = '.wrapper';

  ngOnInit() {
    this.checkGroupsUpdate();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isLoading && changes.isLoading.currentValue !== changes.isLoading.previousValue) {
      this.isLoading = changes.isLoading.currentValue;
    }

    this.checkGroupsUpdate();
  }

  checkGroupsUpdate() {
    this.isEmptyTimelineGroups = !this.groups.length;
    this.isSingleGroup = this.groups.length === 1;
    this.isSingleSurvey = this.groups.length === 1 && this.groups[0].surveys.length === 1;
  }

  trackByFn(index, item) {
    return item.id;
  }

  onScrollDown() {
    this.scrolled.emit(null);
  }
}
