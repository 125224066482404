import { Component, ViewEncapsulation, Inject, OnInit, OnDestroy } from '@angular/core';
import { DATE_SUBMIT_FORMAT, MOMENT_DATE_FILTER_FORMAT } from '../shared/date';
import { SurveyTypeFilterService } from './timeline-filters/survey-type/survey-type.service';
import { debounceTime } from 'rxjs/operators';
import moment from 'moment';
import * as _ from 'underscore';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.ng2.html',
  styleUrls: ['./timeline.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class TimelineComponent implements OnInit, OnDestroy {
  public surveyIdsSubscribtion;
  public surveyIds = [];
  public minInputDate: Date;
  public timelineDataPromise: any;
  public acoountDataPromise: any;
  public timelineItems: Array<any> = [];
  public groupedItems: Array<any> = [];
  public currentPage = 0;
  public limit = 20;
  public dateFrom: string;
  public dateTo = moment().format(DATE_SUBMIT_FORMAT);
  public isDataLoaded = false;
  public isLoadingOnScroll = false;
  public isLoadingOnFilters = false;
  public groupedObjectItems: any = {};
  public totalCompleted = 0;
  public hasNextPage = true;

  constructor(
    private surveyTypeFilterService: SurveyTypeFilterService,
    @Inject('ApiContent') private apiContentService: any
  ) {}

  ngOnInit() {
    this.getInitialData();
    this.surveyIdsSubscribtion = this.surveyTypeFilterService.getSelectedSurveyIdsSubject()
      .pipe(
        debounceTime(1000)
      )
      .subscribe(value => {
        this.onSurveyIdsChange(value);
      });
  }

  private getInitialData() {
    this.acoountDataPromise = this.apiContentService.getAccount();
    this.acoountDataPromise.then((data) => {
      this.dateFrom = moment(data.register_date).format(DATE_SUBMIT_FORMAT);
      // Prevent IE11 bug with date format
      this.minInputDate = moment(data.register_date).toDate();
      this.getTimelineData();
    });
  }

  onScrolled() {
    this.getTimelineData();
    this.isLoadingOnScroll = true;
  }

  onSurveyIdsChange(ids) {
    this.currentPage = 0;

    if (ids) {
      this.surveyIds = ids;
    } else {
      this.surveyIds = [''];
    }

    this.showLoader();
    this.getTimelineData();
  }

  getTimelineData() {
    const surveyIds = Object.assign([], this.surveyIds);

    this.currentPage++;
    this.timelineDataPromise = this.apiContentService.getHistory(this.currentPage, this.limit, this.dateFrom, this.dateTo, surveyIds);
    this.timelineDataPromise.then((data) => {
      this.hasNextPage = !!(data.state._links && data.state._links.next);
      this.isDataLoaded = true;
      this.isLoadingOnScroll = false;

      // Remove previous items when filters changes
      if (this.currentPage === 1) {
        this.groupedObjectItems = {};
        this.groupedItems = [];
      }

      this.getGroupItems(data);
      this.totalCompleted = data.state.totalCompleted;
      this.hideLoader();
    });
  }

  getGroupItems(data) {
    const timelineItems: Array<any> = data._embedded.items;
    let groupedItems;
    let groupedObjectItems;

    groupedObjectItems = _.groupBy(timelineItems, function (item) {
      const date = item.endAt;

      return moment(date).format(DATE_SUBMIT_FORMAT);
    });

    for (const key in groupedObjectItems) {
      if (this.groupedObjectItems[key]) {
        groupedObjectItems[key] = this.groupedObjectItems[key].concat(groupedObjectItems[key]);
      }
    }

    this.groupedObjectItems = _.extend(this.groupedObjectItems, groupedObjectItems);
    groupedItems = Object.keys(this.groupedObjectItems)
      .map(key => ({
        date: key,
        id: this.getGroupId(this.groupedObjectItems[key]),
        surveys: this.groupedObjectItems[key]
      }));
    this.groupedItems = groupedItems;
  }

  getGroupId(passages) {
    let id = '';

    passages.forEach(passage => {
      id += passage.survey.id + '_' + passage.endAt;
    });

    return id;
  }

  onStartDateChange(date: string) {
    this.currentPage = 0;
    this.dateFrom = moment(date, MOMENT_DATE_FILTER_FORMAT).format(DATE_SUBMIT_FORMAT);
    this.showLoader();
  }

  onEndDateChange(date: string) {
    this.currentPage = 0;
    this.dateTo = moment(date, MOMENT_DATE_FILTER_FORMAT).format(DATE_SUBMIT_FORMAT);
    this.showLoader();
  }

  showLoader() {
    this.isLoadingOnFilters = true;
  }

  hideLoader() {
    this.isLoadingOnFilters = false;
  }

  ngOnDestroy() {
    this.surveyIdsSubscribtion.unsubscribe();
  }
}
