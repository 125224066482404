import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TimelineFiltersService {
  private dateFromSubject = new Subject<string>();
  private dateToSubject = new Subject<string>();

  setDateFrom(value) {
    this.dateFromSubject.next(value);
  }

  setDateTo(value) {
    this.dateToSubject.next(value);
  }

  getDateFromSubject() {
    return this.dateFromSubject;
  }

  getDateToSubject() {
    return this.dateToSubject;
  }
}
