import { Component, OnInit, OnDestroy, EventEmitter, Input, Output, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { formatDate } from '@angular/common';
import { DATE_FILTER_FORMAT } from '../../shared/date';
import { TimelineFiltersService } from './timeline-filters.service';

@Component({
  selector: 'app-timeline-filters',
  templateUrl: './timeline-filters.component.ng2.html',
  styleUrls: ['./timeline-filters.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class TimelineFiltersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() minInputDate: Date;
  @Output() startDateChange = new EventEmitter<string>();
  @Output() endDateChange = new EventEmitter<string>();

  minDate: string;
  maxDate: string;
  startDate: string;
  endDate: string;
  datesGroup: FormGroup;
  startDateSubscribtion;
  endDateSubscribtion;

  constructor(private timelineFiltersService: TimelineFiltersService) {}

  ngOnInit() {
    this.minDate = formatDate(this.minInputDate, DATE_FILTER_FORMAT, 'en');
    this.maxDate = formatDate(new Date(), DATE_FILTER_FORMAT, 'en');
    this.startDate = this.minDate;
    this.endDate = this.maxDate;
    this.datesGroup = new FormGroup({
      startDate: new FormControl(this.startDate),
      endDate: new FormControl(this.endDate),
    });

    this.onChanges();
  }

  ngOnDestroy() {
    this.startDateSubscribtion.unsubscribe();
    this.endDateSubscribtion.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.minInputDate && changes.minInputDate.currentValue !== changes.minInputDate.previousValue) {
      this.minDate = formatDate(changes.minInputDate.currentValue, DATE_FILTER_FORMAT, 'en');
    }
  }

  onChanges() {
    this.startDateSubscribtion = this.datesGroup.get('startDate').valueChanges.subscribe(val => {
      this.startDateChange.next(val);
      this.timelineFiltersService.setDateFrom(val);
    });

    this.endDateSubscribtion = this.datesGroup.get('endDate').valueChanges.subscribe(val => {
      this.endDateChange.next(val);
      this.timelineFiltersService.setDateTo(val);
    });
  }

  onStartDateChange(date) {
    this.startDate = date;
    this.datesGroup.get('startDate').setValue(date);
  }

  onEndDateChange(date) {
    this.endDate = date;
    this.datesGroup.get('endDate').setValue(date);
  }
}
