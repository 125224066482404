import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule, downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { TimelineModule } from './timeline/timeline.module';
import { TimelineComponent } from './timeline/timeline.component';
import { ShowPasswordModule } from './shared/components/show-password/show-password.module';
import { ShowPasswordComponent } from './shared/components/show-password/show-password.component';
import { LoggerService } from '@tonic/logging/angular';
import { getDatadogProviders } from '@tonic/logging/angular/datadog';


declare var angular: any;

@NgModule({
  imports: [
    RouterModule.forRoot([]), // TODO: need for LoggingModule. Can be removed when get rid of AngularJS
    BrowserModule,
    UpgradeModule,
    TimelineModule,
    ShowPasswordModule,
  ],
  declarations: [],
  // entryComponents: [],
  providers: [
    getDatadogProviders(),
  ],
})
export class AppModule {
  constructor(
    private upgrade: UpgradeModule
  ) {}
  ngDoBootstrap() {
    this.upgrade.bootstrap(document.documentElement, ['tonicPatientPortal'], {
      strictDi: true
    });
  }
}

angular
  .module('tonicPatientPortal')
  .directive(
    'timeline',
    downgradeComponent({ component: TimelineComponent }) as angular.IDirectiveFactory
  )
  .directive(
    'showPassword',
    downgradeComponent({ component: ShowPasswordComponent }) as angular.IDirectiveFactory
  )
  .factory(
    'loggerService',
    downgradeInjectable(LoggerService) as any
  );
