import { Injectable } from '@angular/core';
import { Question } from './question';
import { Answer } from './answer';
import { ItemType } from './item-type';

@Injectable({
  providedIn: 'root',
})
export class TimelineResultsService {
  buildResultsTree(data) {
    const questions: Map<number, Question> = new Map();

    if (!data) {
      return questions;
    }

    for (const sectionId in data) {
      if (data.hasOwnProperty(sectionId)) {
        const section = data[sectionId];

        if (section.nodeType === ItemType.Section) {
          this.addRootQuestions(questions, section.children);
        }
      }
    }

    return questions;
  }

  private addRootQuestions(questions: Map<number, Question>, data) {
    for (const questionId in data) {
      if (data.hasOwnProperty(questionId)) {
        const questionData = data[questionId];

        if (questionData.nodeType === ItemType.Question) {
          const question = this.createQuestionByData(questionData);

          this.addAnswersToQuestion(question, questionData.answer);
          questions.set(question.id, question);
        }
      }
    }
  }

  private addAnswersToQuestion(question, data) {
    for (const answerId in data) {
      if (data.hasOwnProperty(answerId)) {
        const answerData = data[answerId];

        if (answerData.nodeType === ItemType.Answer) {
          let answerValue: string;

          if (Array.isArray(answerData.answer)) {
            answerValue = answerData.answer.join('<br/>');
          } else {
            answerValue = answerData.answer;
          }

          const answer = new Answer(answerData.id, answerData.title, answerValue);
          const answerSortPosition = parseInt(answerData.sortPosition, 10);
          const attributeSortPosition = parseInt(answerData.attributeSortPosition, 10);

          if (!isNaN(answerSortPosition)) {
            answer.answerSortPosition = answerSortPosition;
          }

          if (!isNaN(attributeSortPosition)) {
            answer.attributeSortPosition = attributeSortPosition;
          }

          this.addQuestionsToAnswer(answer, answerData.children);
          question.addAnswer(answer);
        }
      }
    }
  }

  private addQuestionsToAnswer(answer, data) {
    for (const questionId in data) {
      if (data.hasOwnProperty(questionId)) {
        const questionData = data[questionId];

        if (questionData.nodeType === ItemType.Question) {
          const question = this.createQuestionByData(questionData);

          this.addAnswersToQuestion(question, questionData.answer);
          answer.addQuestion(question);
        }
      }
    }
  }

  private createQuestionByData(data) {
    const question = new Question(data.id, data.alias, data.title);

    question.isHidden = data.options.IsHidden && data.options.IsHidden === '1';
    question.isSkipped = data.skipped;
    question.sortPosition = data.positionEx;

    return question;
  }
}
