import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-show-password',
  templateUrl: './show-password.component.ng2.html',
  styleUrls: ['./show-password.component.scss'],
})

export class ShowPasswordComponent {
  @Input() fieldId: string;
  @Input() isFocused: boolean;
  @Input() isCheckboxChecked: boolean;
  @Output() passwordShowed = new EventEmitter<any>();

  public isChecked = false;
  public inputType = 'password';

  onShowPasswordChange() {
    if (this.isChecked !== this.isCheckboxChecked) {
      this.isChecked = this.isCheckboxChecked;
    }

    if (this.isChecked) {
      this.inputType = 'password';
    } else {
      this.inputType = 'text';
    }

    this.passwordShowed.next({ type: this.inputType, checked: this.isChecked });
    this.isChecked = !this.isChecked;
  }
}
