<main class="main timeline">
  <div class="container" *ngIf="isDataLoaded">
    <div class="timeline-header">
      <div>
        <h1>History</h1>
        <div class="timeline-header-total"><b>{{ totalCompleted }}</b> Surveys Completed</div>
      </div>
      <app-timeline-filters
        *ngIf="dateFrom && dateTo"
        [minInputDate]="minInputDate"
        (startDateChange)="onStartDateChange($event)"
        (endDateChange)="onEndDateChange($event)"
        class="timeline-filters">
      </app-timeline-filters>
    </div>

    <app-timeline-list 
      [groups]="groupedItems"
      [isLoading]="isLoadingOnScroll && hasNextPage"
      (scrolled)="onScrolled()"
    ></app-timeline-list>
  </div>
  <div class="timeline-filters-loader" *ngIf="isLoadingOnFilters">
    <div class="loader loader--orientation-horizontal">
      <svg class="loader__image loader__image--svg">
        <circle cx="16" cy="16" r="12" stroke-linecap="round"/>
      </svg>
      <div class="loader__header">Loading...</div>
    </div>
  </div>
</main>