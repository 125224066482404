export class Survey {
  private _isSelected = false;

  constructor(private _id: number, private _title: string, private _numberOfPassages: number) {}

  get id() {
    return this._id;
  }

  get title() {
    return this._title;
  }

  get numberOfPassages() {
    return this._numberOfPassages;
  }

  get isSelected() {
    return this._isSelected;
  }

  set isSelected(value) {
    this._isSelected = value;
  }
}
