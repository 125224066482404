import { Component, Input, ViewEncapsulation, OnInit } from '@angular/core';

interface Survey {
  id: number;
  title: string;
  icon: string;
}

interface ItemData {
  survey: Survey;
  category: string;
  endAt: string;
  status: string;
  _links: any;
}

@Component({
  selector: 'app-timeline-item',
  templateUrl: './timeline-item.component.ng2.html',
  styleUrls: ['./timeline-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class TimelineItemComponent implements OnInit {
  @Input() public surveyData: ItemData;

  public isExpired = false;
  public isShowResults = false;
  public title = '';
  public icon = '';
  public date = '';
  public category = '';
  public resultsLink: string = null;

  ngOnInit() {
    this.isExpired = this.surveyData.status === 'expired';
    this.title = this.surveyData.survey.title;
    this.icon = this.surveyData.survey.icon;
    this.date = this.surveyData.endAt;
    this.category = this.getSurveyCategoryName();

    if (this.surveyData._links && this.surveyData._links.browseResult) {
      this.resultsLink = this.surveyData._links.browseResult.href;
    }
  }

  public getSurveyCategoryName() {
    const surveySections = {
      about_me: 'About Me',
      daily_diary: 'Daily Entries',
      scheduled: 'Scheduled Survey',
      study: 'Journal Entries',
    };

    return surveySections[this.surveyData.category];
  }
}
