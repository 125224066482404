import {
  Input,
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  Inject,
  ElementRef,
  HostListener,
  ViewChild
} from '@angular/core';
import { SurveyTypeFilterService } from './survey-type.service';
import { SurveyType } from './survey-type';
import { TimelineFiltersService } from '../timeline-filters.service';
import { DATE_SUBMIT_FORMAT, MOMENT_DATE_FILTER_FORMAT } from '../../../shared/date';
import moment from 'moment';

@Component({
  selector: 'app-timeline-survey-type',
  templateUrl: './survey-type.component.ng2.html',
  styleUrls: ['./survey-type.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class TimelineSurveyTypeComponent implements OnInit, OnDestroy {
  @Input() dateFrom;
  @Input() dateTo;
  public isFilterPopupShown = false;
  public surveyTypes: Array<SurveyType>;
  @ViewChild('filtersButton', { static: true }) private filtersButton: ElementRef;
  @ViewChild('clearAllButton', { static: true }) private clearAllButton: ElementRef;
  @ViewChild('filtersPopup', { static: true }) private filtersPopup: ElementRef;
  private dateFromSubscribtion;
  private dateToSubscribtion;

  constructor(
    private surveyTypeFilterService: SurveyTypeFilterService,
    @Inject('ApiContent') private apiContentService: any,
    private timelineFiltersService: TimelineFiltersService
  ) {}

  @HostListener('document:click', ['$event']) clickedOutside(event) {
    const targetElement = event.target as HTMLElement;
    const isClickedOutsideFilterButton = targetElement && !this.filtersButton.nativeElement.contains(targetElement);
    const isClickedOutsideClearAllButton = targetElement && !this.clearAllButton.nativeElement.contains(targetElement);
    const isClickedOutsidePopup =  targetElement && !this.filtersPopup.nativeElement.contains(targetElement);

    if (this.isFilterPopupShown && isClickedOutsideFilterButton && isClickedOutsideClearAllButton && isClickedOutsidePopup) {
      this.setFilterState();
    }
  }

  ngOnInit() {
    this.dateFromSubscribtion = this.timelineFiltersService
      .getDateFromSubject()
      .subscribe(value => {
        this.dateFrom = moment(value, MOMENT_DATE_FILTER_FORMAT).format(DATE_SUBMIT_FORMAT);
        this.getFilters(false);
      });

    this.dateToSubscribtion = this.timelineFiltersService
      .getDateToSubject()
      .subscribe(value => {
        this.dateTo = moment(value, MOMENT_DATE_FILTER_FORMAT).format(DATE_SUBMIT_FORMAT);
        this.getFilters(false);
      });

    this.getFilters(true);
  }

  ngOnDestroy() {
    if (this.dateFromSubscribtion) {
      this.dateFromSubscribtion.unsubscribe();
    }

    if (this.dateToSubscribtion) {
      this.dateToSubscribtion.unsubscribe();
    }
  }

  getFilters(isFirstLoad: boolean) {
    this
      .apiContentService
      .getHistoryFilter(this.dateFrom, this.dateTo)
      .then((data) => {
        this.surveyTypeFilterService.loadDataToFilter(data);
        this.surveyTypes = this.surveyTypeFilterService.surveyTypes;

        if (!isFirstLoad) {
          this.surveyTypeFilterService.rebuildSelectedSurveyIds();
        }
      });
  }

  setFilterState() {
    if (this.passagesNotEmpty()) {
      this.isFilterPopupShown = !this.isFilterPopupShown;
    }
  }

  passagesNotEmpty() {
    let passagesCount = 0;

    if (this.surveyTypes) {
      this.surveyTypes.forEach(surveyType => {
        passagesCount = passagesCount + surveyType.passages;
      });
    }

    return passagesCount > 0;
  }

  hasSelectedFilters() {
    return this.surveyTypeFilterService.hasSelectedFilters();
  }

  clearAll() {
    this.surveyTypeFilterService.clearAll();
  }
}
