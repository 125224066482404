import {
  Input,
  Output,
  EventEmitter,
  ElementRef,
  Directive,
  OnInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { DATE_PICKER_FORMAT } from '../../date';

declare var $: any;

@Directive({
  selector: '[appDatepicker]',
  exportAs: 'datePickerDirective'
})

export class DatePickerDirective implements OnInit, OnChanges {
  @Input() minDate: string;
  @Input() maxDate: string;
  @Input() dateFormat: string;
  @Output() dateChange = new EventEmitter();

  constructor(public element: ElementRef) {}

  ngOnInit() {
    const element = $(this.element.nativeElement);

    element
      .datepicker({
        dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        showAnim: '',
        changeMonth: true,
        changeYear: true,
        hideIfNoPrevNext: true,
        monthNamesShort: $.datepicker.regional['en'].monthNames,
        dateFormat: this.dateFormat || DATE_PICKER_FORMAT,
        minDate: this.minDate || '',
        maxDate: this.maxDate || '',
        onSelect: (value: string) => {
          this.dateChange.next(value);
        },
        beforeShow: function() {
          element.parent().append(element.datepicker('widget'));
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.minDate && changes.minDate.currentValue !== changes.minDate.previousValue) {
      $(this.element.nativeElement)
        .datepicker('option', 'minDate', changes.minDate.currentValue);
    }

    if (changes.maxDate && changes.maxDate.currentValue !== changes.maxDate.previousValue) {
      $(this.element.nativeElement)
        .datepicker('option', 'maxDate', changes.maxDate.currentValue);
    }
  }

  public showDatePicker() {
    $(this.element.nativeElement)
      .datepicker('show');
  }
}
