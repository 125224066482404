<div class="survey-type-buttons">
  <ng-template ngFor let-surveyType [ngForOf]="surveyTypes">
    <div class="tag" *ngIf="surveyType.isSelected && !surveyType.isChildsVisible()">
      {{ surveyType.title }}
      <div class="tag__close"
           (click)="removeFilter(surveyType)">
           <div class="tag__close_hover"></div>
      </div>
    </div>

    <ng-template [ngIf]="surveyType.isChildsVisible()">
      <ng-template ngFor let-survey [ngForOf]="surveyType.surveys">
        <div class="tag" *ngIf="survey.isSelected">
          {{ survey.title }}
          <div class="tag__close"
               (click)="removeFilter(survey)">
               <div class="tag__close_hover"></div>
          </div>
        </div>
      </ng-template>
    </ng-template>
  </ng-template>
</div>