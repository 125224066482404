import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../shared/shared.module';
import { apiContentServiceProvider } from '../ajs-upgraded-providers';
import { TimelineComponent } from './timeline.component';
import { TimelineListComponent } from './timeline-list/timeline-list.component';
import { TimelineGroupComponent } from './timeline-group/timeline-group.component';
import { TimelineItemComponent } from './timeline-item/timeline-item.component';
import { TimelineFiltersComponent } from './timeline-filters/timeline-filters.component';
import { TimelineSurveyTypeComponent } from './timeline-filters/survey-type/survey-type.component';
import { TimelineSurveyTypeSelectorComponent } from './timeline-filters/survey-type-selector/survey-type-selector.component';
import { TimelineSurveyTypeListComponent } from './timeline-filters/survey-type-list/survey-type-list.component';
import { TimelineResultsComponent } from './timeline-results/timeline-results.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    InfiniteScrollModule,
    NgScrollbarModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
  ],
  declarations: [
    TimelineComponent,
    TimelineListComponent,
    TimelineGroupComponent,
    TimelineItemComponent,
    TimelineFiltersComponent,
    TimelineSurveyTypeComponent,
    TimelineSurveyTypeSelectorComponent,
    TimelineSurveyTypeListComponent,
    TimelineResultsComponent,
  ],
  // entryComponents: [
  //   TimelineComponent,
  // ],
  providers: [
    apiContentServiceProvider,
  ],
  exports: [
    TimelineComponent
  ]
})

export class TimelineModule {}
