import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ShowPasswordComponent } from './show-password.component';

@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  declarations: [
    ShowPasswordComponent,
  ],
  // entryComponents: [
  //   ShowPasswordComponent,
  // ],
  exports: [
    ShowPasswordComponent
  ]
})

export class ShowPasswordModule {}
