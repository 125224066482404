<div class="survey-type-buttons">
  <div 
    class="survey-type-button orange"
    id="filtersButton"
    #filtersButton
    [ngClass]="{'active': isFilterPopupShown, 'disabled': !passagesNotEmpty()}"
    (click)="setFilterState()"
  >
    <svg class="icon icon-plus">
      <use xlink:href="#icon-plus"></use>
    </svg>
    <span class="name"> Filters</span>
  </div>
  <div class="survey-type-button"
       id="clearAllButton"
       #clearAllButton
       (click)="clearAll()"
       [hidden]="!hasSelectedFilters()"
  >
    Clear Filters
  </div>
  <div 
    id="filtersPopup"
    #filtersPopup>
      <app-timeline-survey-type-selector 
        *ngIf="isFilterPopupShown"
        [surveyTypes]="surveyTypes">
      </app-timeline-survey-type-selector>
  </div>
</div>
<app-timeline-survey-type-list [surveyTypes]="surveyTypes"></app-timeline-survey-type-list>