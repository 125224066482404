<div class="timeline-item" [ngClass]="{'timeline-item__expired': isExpired}">
  <div 
    class="timeline-info"
    [ngClass]="{'active': isShowResults}"
  >
    <div class="timeline-item-group-border"></div>
    <div class="timeline-item-content">
      <div class="timeline-item-content-left">
        <div class="timeline-image">
          <img class="survey-image" *ngIf="icon" [src]="icon" alt="{{ title }}">
          <img class="survey-image" *ngIf="!icon" src="images/placeholder.png"  alt="{{ title }}">
        </div>
        <div class="timeline-expired" *ngIf="isExpired">
          Expired
        </div>
        <div class="timeline-info-survey-title">
          <p>{{ title }}</p>
        </div>
        <div class="timeline-info-survey-time">
          <p>
            <span *ngIf="!isExpired">{{ date | date: 'hh:mm a' }}</span>
            {{ category }}
          </p>
        </div>
      </div>
      <div class="timeline-item-content-right" *ngIf="resultsLink">
        <div 
          class="timeline-responses-button" 
          [ngClass]="{'active': isShowResults}"
          (click)="isShowResults = !isShowResults">
          Responses
          <svg class="icon icon-arrow-up">
            <use xlink:href="#icon-arrow-up"></use>
          </svg>
          <svg class="icon icon-arrow-down">
            <use xlink:href="#icon-arrow-down"></use>
          </svg>
        </div>
      </div>
    </div>
    <app-timeline-results
      [resultsLink]="resultsLink"
      *ngIf="isShowResults && resultsLink">
    </app-timeline-results>
  </div>
</div>
